<template lang="">
  <section>
    <!-- Titulo de la vista -->
    <v-breadcrumbs>
      <template v-for="(item, index) in breadcrumbs">
        <v-breadcrumbs-item
          :key="index"
          :href="item.href"
          :disabled="item.disabled"
          v-if="item.show"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider v-if="index < breadcrumbs.length - 1">
          /
        </v-breadcrumbs-divider>
      </template>
    </v-breadcrumbs>

    <!-- Tabla -->
    <v-row>
      <v-col cols="12"> Listado de OBS </v-col>
      <v-col cols="12">
        <data-table-component
          :headers="headers"
          :items="listadoOBS"
          v-models:select="porPaginaModel"
          v-models:pagina="paginaModel"
          @paginar="paginar"
          :total_registros="total_registros"
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="verOBS(item)" v-bind="attrs" v-on="on">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-col>
    </v-row>

    <!-- Botón de regresar -->
    <v-row>
      <v-col cols="12">
        <v-btn class="mt-5" color="primary" outlined @click="regresar">
          Regresar
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex'
// Importación de componentes
import DataTableComponent from '../../components/DataTableComponent.vue'
export default {
  name: 'obsProveedorView',
  components: {
    DataTableComponent
  },
  data: () => ({
    headers: [
      { text: 'OBS', align: 'center', value: 'obs' },
      { text: 'Título', align: 'center', value: 'titulo' },
      { text: 'Descripción', align: 'center', value: 'descripcion' },
      { text: 'Acciones', align: 'center', value: 'acciones' }
    ],
    listadoOBS: [],
    porPaginaModel: 10,
    paginaModel: 1,
    total_registros: 0
  }),
  computed: {
    ...mapState('proveedores', ['provider']),
    breadcrumbs () {
      return [
        {
          show: !this.haveRole('ROLE_SUPERVISOR'),
          text: 'RUPES',
          disabled: false,
          href: `/rupes`
        },
        {
          show: true,
          text: this.provider?.nombre,
          disabled: false,
          href: `/proveedor/${this.$route.params.id_proveedor}`
        },
        {
          show: true,
          text: 'OBS',
          disabled: true
        }
      ]
    }
  },
  methods: {
    ...mapActions('proveedores', ['getProvider']),
    async obtenerOBS () {
      const response = await this.services.ProveedoresProcuctos.getObsProvider(
        {
          pagination: true,
          page: this.paginaModel,
          per_page: this.porPaginaModel
        },
        this.$route.params.id_proveedor
      )

      if (response.status === 200) {
        this.listadoOBS = []
        this.total_registros = parseInt(response.headers.total_rows)
        for (const { id, obs_nombre, titulo, descripcion } of response.data) {
          const nuevoElemento = {
            id: id,
            obs: obs_nombre,
            titulo: titulo,
            descripcion: descripcion
          }
          this.listadoOBS.push(nuevoElemento)
        }
      } else {
        this.temporalAlert({
          show: true,
          message: response.data.message,
          type: 'error'
        })
      }
    },
    paginar (filters) {
      this.paginaModel = filters.pagina
      this.porPaginaModel = filters.cantidad_por_pagina

      this.obtenerOBS()
    },
    // Funciones de navegación
    verOBS (item) {
      this.$router.push({
        name: 'obs-proveedor-ver',
        params: {
          id_proveedor: this.$route.params.id_proveedor,
          id_obs: item.id
        }
      })
    },
    regresar () {
      this.$router.push({
        name: 'proveedor',
        params: { id_proveedor: this.$route.params.id_proveedor }
      })
    }
  },
  async created () {
    await this.getProvider(Number(this.$route.params.id_proveedor))
    this.obtenerOBS()
  }
}
</script>
<style lang=""></style>
