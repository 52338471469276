<template lang="">
  <section>
    <!-- Titulo de la vista -->
    <v-breadcrumbs>
      <template v-for="(item, index) in breadcrumbs">
        <v-breadcrumbs-item
          :key="index"
          :href="item.href"
          :disabled="item.disabled"
          v-if="item.show"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider v-if="index < breadcrumbs.length - 1">
          /
        </v-breadcrumbs-divider>
      </template>
    </v-breadcrumbs>

    <!-- Información del tipo de contribuyente -->
    <v-row class="filters-row">
      <v-col cols="12">
        <h6 class="grayMinsal--text" v-if="proveedor != {}">
          Proveedor {{ obtenerTipoContribuidor }} -
          {{ proveedor.tipo_contribuyente }}
        </h6>
      </v-col>
    </v-row>

    <!-- Expansion panel con la información de los documentos -->
    <v-row>
      <v-col cols="12">
        <v-expansion-panels focusable class="mt-12">
          <v-expansion-panel
            v-for="(item, index) in documentosMostrados"
            :key="index"
            class="panel mt-2"
          >
            <v-expansion-panel-header>{{
              item.nombre
            }} <span v-if="item.requerido" class="ml-2 error--text">Requerido</span></v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12">
                  <!-- Numero de documento -->
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.numero_documento_label ?? 'Número de documento' }}</v-list-item-title>
                      <v-list-item-subtitle class="text--error">
                        {{ item.numero_documento }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <!-- Fecha de vencimiento -->
                  <v-list-item v-if="item.mostrar_fecha">
                    <v-list-item-content>
                      <v-list-item-title>{{ capitalize(item.fecha_label ?? 'Fecha de vencimiento') }}</v-list-item-title>
                      <v-list-item-subtitle class="text--error">
                        {{ item.fecha_vencimiento }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="item.mostrar_libro_registro">
                    <v-list-item-content>
                      <v-list-item-title>Libro de registro</v-list-item-title>
                      <v-list-item-subtitle class="text--error">
                        {{ item.libro_registro ?? '-' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <!-- Descargar archivo-->
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Archivo</v-list-item-title>
                      <v-list-item-subtitle class="text--error">
                        <v-btn
                          @click="
                            item.url_archivo != null &&
                              descargarDocumento(
                                item.url_archivo,
                                item.nombre_archivo
                              )
                          "
                          text
                          :class="item.url_archivo != null ? 'secondary' : ''"
                        >
                          {{ item.nombre_archivo }}</v-btn
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- Botón de regresar -->
    <v-row>
      <v-col cols="12">
        <v-btn class="mt-5" color="primary" outlined @click="regresar">
          Regresar
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { capitalize } from 'lodash';
import moment from 'moment'

export default {
  name: 'documentacionProveedorView',
  data: () => ({
    documentosRequeridos: [],
    proveedor: {}
  }),
  methods: {
    ...mapActions('proveedores', ['getProvider']),
    capitalize,
    //Métodos para el consumo de servicios
    async obtenerDocumentacion () {
      const response =
        await this.services.Proveedores.getProviderDocumentRequired(
          {},
          this.$route.params.id_proveedor
        )
      if (response.status === 200) {
        this.documentosRequeridos = response.data
      }
    },
    async obtenerProveedor () {
      const response = await this.services.Proveedores.getProviderInfo(
        this.$route.params.id_proveedor
      )
      if (response.status === 200) {
        this.proveedor = response.data
      }
    },
    // Método para descargar el archivo
    async descargarDocumento (url_archivo, nombre_archivo) {
      const response = await this.services.Proveedores.getDocumentoProveedor({
        documento: url_archivo
      })
      // Descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response?.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${nombre_archivo}`)
      document.body.appendChild(link)
      link.click()
    },
    regresar () {
      this.$router.push({
        name: 'proveedor',
        params: { id_proveedor: this.$route.params.id_proveedor }
      })
    }
  },
  computed: {
    ...mapState('proveedores', ['provider']),
    breadcrumbs () {
      return [
        {
          show: !this.haveRole('ROLE_SUPERVISOR'),
          text: 'RUPES',
          disabled: false,
          href: `/rupes`
        },
        {
          show: true,
          text: this.provider?.nombre,
          disabled: false,
          href: `/proveedor/${this.$route.params.id_proveedor}`
        },
        {
          show: true,
          text: 'Documentación',
          disabled: true
        }
      ]
    },
    obtenerTipoContribuidor () {
      const nombreIdentificacionTributaria =
        this.proveedor?.identificacion_tributaria?.nombre

      if (
        nombreIdentificacionTributaria &&
        nombreIdentificacionTributaria.includes('Salvadoreño')
      ) {
        return 'nacional'
      }

      return 'extranjero'
    },
    /**
     * Refactoriza los documentos del proveedor para que se puedan mostrar en la vista
     * @returns {Array} Lista de documentos refactorizados
     */
    documentosMostrados () {
      let documentosMostrados = []

      if (this.documentosRequeridos.length > 0 && this.proveedor.documentos) {
        this.documentosRequeridos.forEach(documento => {
          // Buscamos el documento en la lista de documentos del proveedor
          const documentoEncontrado = this.proveedor.documentos.find(
            documentoProveedor =>
              documentoProveedor.id_tipo_documento ===
              documento.id_tipo_documento
          )

          // Formateamos la fecha de vencimiento para que se muestre en el formato correcto
          let fechaVencimiento = '-'
          if (documentoEncontrado?.fecha_vencimiento) {
            fechaVencimiento = moment(
              documentoEncontrado?.fecha_vencimiento
            ).format('DD/MM/YYYY')
          }
          const documentoRefactorizado = {
            nombre: documento.tipo_documento,
            formato: documento.formato,
            numero_documento: documentoEncontrado?.numero_documento || '-',
            fecha_vencimiento: fechaVencimiento,
            nombre_archivo:
              documentoEncontrado?.nombre_documento || "no disponible",
            url_archivo: documentoEncontrado?.documento || null,
            requerido: documento.requerido,
            fecha_label: documento?.fecha_label,
            mostrar_fecha: documento.fecha_expiracion,
            mostrar_libro_registro: documento.libro_registro,
            libro_registro: documentoEncontrado?.libro_registro,
            numero_documento_label: documento.numero_documento_label,
          };

          documentosMostrados.push(documentoRefactorizado)
        })
      }

      return documentosMostrados
    }
  },
  async mounted () {
    await this.getProvider(Number(this.$route.params.id_proveedor))
    await this.obtenerDocumentacion()
    await this.obtenerProveedor()
  }
}
</script>
<style lang="scss" scoped>
:deep(.v-expansion-panel--active > .v-expansion-panel-header) {
  background: #111c4e !important;
  color: white !important;
}
:deep(.v-expansion-panel--active .v-expansion-panel-header__icon i) {
  color: white !important;
}
</style>
