<template lang="">
  <section>
    <!-- Titulo de la vista -->
    <v-breadcrumbs>
      <template v-for="(item, index) in breadcrumbs">
        <v-breadcrumbs-item
          :key="index"
          :href="item.href"
          :disabled="item.disabled"
          v-if="item.show"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider v-if="index < breadcrumbs.length - 1"
          >/</v-breadcrumbs-divider
        >
      </template>
    </v-breadcrumbs>

    <!-- Tabla con información de los contactos -->
    <v-row>
      <v-col cols="12"> Lista de contactos</v-col>
      <v-col cols="12">
        <data-table-component
          :headers="headers"
          :items="listaDeContactos"
          v-models:select="porPaginaModel"
          v-models:pagina="paginaModel"
          @paginar="paginar"
          :total_registros="totalRegistros"
        >
          <template #item.id="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip
              v-if="
                item.estado_solicitud_proveedor != 'Aprobado' &&
                item.estado_solicitud_proveedor != 'Cancelado'
              "
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  size="30px"
                  color="primary"
                  @click="verDetalleContacto(item)"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span> Visualizar </span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-col>
    </v-row>

    <!-- Modal para ver el detalle del contacto -->
    <v-dialog v-model="modalDetalleContacto" max-width="750px">
      <v-card>
        <div class="d-flex justify-start px-2 pt-2">
          <v-btn icon @click="modalDetalleContacto = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="secondary--text px-7 pb-7"
          >Detalle del contacto</v-card-title
        >
        <v-card-text>
          <v-list dense>
            <!-- Tipo de documento -->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subtitle-1"
                  >Tipo de documento:</v-list-item-title
                >
                <v-list-item-subtitle>{{
                  detalleContacto.tipo_documento
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!-- Numero de documento -->

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subtitle-1"
                  >Número de documento:</v-list-item-title
                >
                <v-list-item-subtitle>{{
                  detalleContacto.numero_documento
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!-- Nombre -->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subtitle-1"
                  >Nombre:</v-list-item-title
                >
                <v-list-item-subtitle>{{
                  detalleContacto.nombre
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!-- Correo electrónico -->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subtitle-1"
                  >Correo electrónico:</v-list-item-title
                >
                <v-list-item-subtitle>{{
                  detalleContacto.correo_electronico
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!-- Tipo de contacto -->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subtitle-1"
                  >Tipo de contacto:</v-list-item-title
                >
                <v-list-item-subtitle>{{
                  detalleContacto.tipo_contacto
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!-- Listado de teléfonos -->
            <v-list-item
              v-for="telefono in detalleContacto.rutas_comunicacion"
              :key="telefono.id"
            >
              <v-list-item-content>
                <v-list-item-title class="subtitle-1"
                  >{{ telefono.titulo }}:</v-list-item-title
                >
                <v-list-item-subtitle>{{
                  telefono.contacto
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!-- Dirección -->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subtitle-1"
                  >Dirección:</v-list-item-title
                >
                <v-list-item-subtitle>{{
                  detalleContacto.direccion
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!-- Cotizaciones -->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subtitle-1"
                  >¿Enviará cotizaciones a UCP?
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  detalleContacto.cotizacion_uaci ? 'Sí' : 'No'
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subtitle-1">OBS:</v-list-item-title>
                <v-list-item-subtitle> </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-data-table
              :headers="headersObs"
              :items="detalleContacto.obs"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:[`item.obs`]="{ item }">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >{{ item.codigo }} -
                      {{ item.nombre }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-data-table>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="modalDetalleContacto = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Botón de regresar -->
    <v-row>
      <v-col cols="12">
        <v-btn class="mt-5" color="primary" outlined @click="regresar">
          Regresar
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import DataTableComponent from '../../components/DataTableComponent.vue'
export default {
  name: 'contactosProveedorView',
  components: {
    DataTableComponent
  },
  data: () => ({
    headers: [
      { text: 'N°', align: 'center', value: 'id' },
      { text: 'Nombre', align: 'center', value: 'nombre' },
      {
        text: 'Número de identificación',
        align: 'center',
        value: 'numero_documento'
      },
      {
        text: 'Correo',
        align: 'center',
        value: 'correo_electronico',
        sortable: false
      },
      {
        text: 'Tipo',
        align: 'center',
        value: 'tipo_contacto',
        sortable: false
      },
      { text: 'Acciones', align: 'center', value: 'actions', sortable: false }
    ],
    listaDeContactos: [],
    totalRegistros: 0,
    porPaginaModel: 10,
    paginaModel: 1,
    modalDetalleContacto: false,
    detalleContacto: {},
    headersObs: [{ text: 'OBS', align: 'start', value: 'obs' }]
  }),
  computed: {
    ...mapState('proveedores', ['provider']),
    breadcrumbs () {
      return [
        {
          show: !this.haveRole('ROLE_SUPERVISOR'),
          text: 'RUPES',
          disabled: false,
          href: `/rupes`
        },
        {
          show: true,
          text: this.provider?.nombre,
          disabled: false,
          href: `/proveedor/${this.$route.params.id_proveedor}`
        },
        {
          show: true,
          text: 'Contactos',
          disabled: true
        }
      ]
    }
  },
  methods: {
    ...mapActions('proveedores', ['getProvider']),
    // Funciones para consumir los servicios
    async obtenerContactos () {
      const response = await this.services.Proveedores.getProviderContacts({
        pagination: true,
        page: this.paginaModel,
        per_page: this.porPaginaModel,
        id_proveedor: this.$route.params.id_proveedor
      })
      if (response.status === 200) {
        this.listaDeContactos = response?.data
        this.totalRegistros = Number(response.headers.total_rows)
      }
    },
    // Mostar el detalle del contacto
    async verDetalleContacto (contacto) {
      // Obtenemos el detalle del contacto
      const response = await this.services.Proveedores.getProviderContact(
        contacto.id
      )

      if (response.status === 200) {
        contacto = response.data
        this.detalleContacto = contacto
        this.modalDetalleContacto = true
      }
    },
    paginar (filters) {
      this.paginaModel = filters.pagina
      this.porPaginaModel = filters.cantidad_por_pagina

      this.obtenerContactos()
    },
    regresar () {
      this.$router.push({
        name: 'proveedor',
        params: { id_proveedor: this.$route.params.id_proveedor }
      })
    }
  },
  async created () {
    await this.getProvider(Number(this.$route.params.id_proveedor))
    this.obtenerContactos()
  }
}
</script>
<style lang=""></style>
