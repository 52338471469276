<template lang="">
  <section>
    <!-- Titulo de la vista -->
    <v-breadcrumbs>
      <template v-for="(item, index) in breadcrumbs">
        <v-breadcrumbs-item
          :key="index"
          :href="item.href"
          :disabled="item.disabled"
          v-if="item.show"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider v-if="index < breadcrumbs.length - 1">
          /
        </v-breadcrumbs-divider>
      </template>
    </v-breadcrumbs>

    <!-- 'Tabla con información de los giros empresariales '  -->
    <v-row>
      <v-col cols="12"> Listado de giros</v-col>
      <v-col cols="12">
        <!-- Se utilizara un v-data-table con la paginacion oculta ya que no se necesita paginar -->
        <v-data-table
          :headers="headers"
          :items="giros"
          :hide-default-footer="true"
        >
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Botón de regresar -->
    <v-row>
      <v-col cols="12">
        <v-btn class="mt-5" color="primary" outlined @click="regresar">
          Regresar
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'giroEmpresarialView',
  data: () => ({
    headers: [{ text: 'Nombre', align: 'start', value: 'nombre' }],
    giros: []
  }),
  computed: {
    ...mapState('proveedores', ['provider']),
    breadcrumbs () {
      return [
        {
          show: !this.haveRole('ROLE_SUPERVISOR'),
          text: 'RUPES',
          disabled: false,
          href: `/rupes`
        },
        {
          show: true,
          text: this.provider?.nombre,
          disabled: false,
          href: `/proveedor/${this.$route.params.id_proveedor}`
        },
        {
          show: true,
          text: 'Giros',
          disabled: true
        }
      ]
    }
  },
  methods: {
    ...mapActions('proveedores', ['getProvider']),
    async obtenerGiros () {
      const response =
        await this.services.Proveedores.getBusinessActivitiesList({
          id_proveedor: this.$route.params.id_proveedor
        })
      if (response.status === 200) {
        this.giros = response.data
      }
    },
    regresar () {
      this.$router.push({
        name: 'proveedor',
        params: { id_proveedor: this.$route.params.id_proveedor }
      })
    }
  },
  async created () {
    await this.getProvider(Number(this.$route.params.id_proveedor))
    this.obtenerGiros()
  }
}
</script>
<style lang=""></style>
