<template lang="">
  <section>
    <!-- Titulo de la vista -->
    <v-row>
      <v-col class="text-h6 secondary--text">
        Proveedor - Configuración de OBS</v-col
      >
    </v-row>

    <!-- Encabezado del OBS -->
    <v-row>
      <v-col cols="12">
        <div class="text-subtitle1">
          <p class="grayMinsal--text mb-n1">Configuración de OBS</p>
          {{ obs?.obs_codigo }} - {{ obs?.obs_nombre }}
        </div>
      </v-col>
      <v-col cols="12">
        <div class="text-subtitle1">
          <p class="grayMinsal--text mb-n1">Título</p>
          {{ obs?.titulo }}
        </div>
      </v-col>
      <v-col cols="12">
        <div class="text-subtitle1">
          <p class="grayMinsal--text mb-n1">Descripción</p>
          {{ obs?.descripcion }}
        </div>
      </v-col>
    </v-row>
    7825-3937
    <!-- Tabla de presentaciones -->
    <v-row>
      <v-col cols="12">
        <data-table-component
          :headers="headers"
          :items="presentaciones"
          v-models:select="porPaginaModel"
          v-models:pagina="paginaModel"
          @paginar="paginar"
          :total_registros="total_registros"
        >
          <template v-slot:[`item.detalle`]="{ item }">
            <p class="mb-0">
              {{ item.etiquetas.map((col) => col.nombre).join(", ") }}
            </p>
          </template>
          <template v-slot:[`item.precio`]="{ item }">
            <p class="mb-0">$ {{ item.precio_unitario }}</p>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip
              v-if="
                item.estado_solicitud_proveedor != 'Aprobado' &&
                item.estado_solicitud_proveedor != 'Cancelado'
              "
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  size="30px"
                  color="primary"
                  @click="getPresentationPreview(item)"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span> Visualizar </span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-col>
    </v-row>

    <!-- Botón de regresar -->
    <v-row>
      <v-col cols="12">
        <v-btn class="mt-5" color="primary" outlined @click="regresar">
          Regresar
        </v-btn>
      </v-col>
    </v-row>

    <!-- Modal para ver el detalle de la presentación -->

    <v-dialog v-model="showPreview" width="1000">
      <v-card>
        <div class="d-flex px-3 pt-3">
          <v-btn @click="showPreview = false" large icon
            ><v-icon>mdi-window-close</v-icon></v-btn
          >
        </div>
        <div class="px-15 pb-15">
          <ObsPreview :obs="obsPresentationPreview" />
        </div>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import DataTableComponent from "../../components/DataTableComponent.vue";
import ObsPreview from "@/components/ObsPreviewComponent.vue";

export default {
  name: "obsProveedorVerView",
  components: {
    DataTableComponent,
    ObsPreview,
  },
  data: () => ({
    loading: false,
    obs: null,
    headers: [
      { text: "Presentación", align: "start", value: "nombre" },
      { text: "Unidad de venta", align: "start", value: "nombre_presentacion" },
      { text: "Detalle", align: "start", value: "detalle" },
      { text: "Precio ($)", align: "right", value: "precio" },
      { text: "Acciones", align: "center", value: "acciones" },
    ],
    presentaciones: [],
    porPaginaModel: 10,
    paginaModel: 1,
    total_registros: 0,
    obsPresentationPreview: {},
    showPreview: false,
  }),
  methods: {
    async obtenerOBS() {
      this.loading = true;
      const response =
        await this.services.ProveedoresProcuctos.getProductoProveedor(
          this.$route.params.id_obs
        );

      if (response.status === 200) {
        this.obs = response.data;
      } else {
        this.temporalAlert({
          show: true,
          message: response.data.message,
          type: "error",
        });
      }

      this.loading = false;
    },
    paginar(filters) {
      this.paginaModel = filters.pagina;
      this.porPaginaModel = filters.cantidad_por_pagina;

      this.obtenerPresentaciones();
    },
    async obtenerPresentaciones() {
      const response =
        await this.services.Proveedores.getProviderOBSPresentation(
          this.$route.params.id_obs,
          {
            pagination: true,
            page: this.paginaModel,
            per_page: this.porPaginaModel,
          }
        );
      if (response.status === 200) {
        this.presentaciones = response.data;
        this.total_registros = parseInt(response.headers.total_rows);
      } else {
        this.temporalAlert({
          show: true,
          message: response.data.message,
          type: "error",
        });
      }
    },
    async getPresentationPreview(item) {
      let response =
        await this.services.Proveedores.getProviderOBSPresentationDetail(
          item.id
        );
      if (response?.status == 200) {
        this.obsPresentationPreview = {
          titulo: response?.data.obs,
          descripcion: response?.data.obs_descripcion,
          nombre_presentacion: response?.data.nombre,
          detalle: response?.data.etiquetas,
          obs_foto: response?.data.obs_fotos,
          id: response?.data.id,
          precio_unitario: response?.data.precio_unitario,
          unidad_presentacion: response?.data.nombre_presentacion,
          nombre_obs: this.obs?.obs_nombre,
          codigo: this.obs?.obs_codigo,
        };
        this.showPreview = true;
      }
    },
    regresar() {
      this.$router.push({
        name: "obs-proveedor",
        params: { id_proveedor: this.$route.params.id_proveedor },
      });
    },
  },
  mounted() {
    this.obtenerOBS();
    this.obtenerPresentaciones();
  },
};
</script>
<style lang=""></style>
